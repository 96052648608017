import { GetStaticProps } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { Button } from 'components/Button';

import { DEFAULT_LOCALE } from '@/constants/global';
import { Route } from '@/constants/routes';

const Image404 = require('assets/images/404.png');

export const Page404 = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleGoToMain = () => router.push(Route.Home);

  return (
    <>
      <NextSeo
        description={t('404.pageDescription')}
        openGraph={{
          title: t('404.pageTitle'),
          description: t('404.pageDescription'),
        }}
        title={t('404.pageTitle')}
      />
      <div className="h-full flex flex-col justify-center items-center pt-28">
        <div className="flex flex-col items-center px-6 mb-4">
          <Image alt={t('404.heading')} placeholder="blur" src={Image404} width={420} />
          <h1 className="font-bold text-4xl">{t('404.heading')}</h1>
          <span className="text-lg mt-2 mb-4">{t('404.subheading')}</span>
          <Button className="h-fit px-10" onClick={handleGoToMain} title={t('404.cta')} />
        </div>
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
    props: {
      ...(await serverSideTranslations(locale || DEFAULT_LOCALE, ['default'])),
    },
  });

export default Page404;
